/* eslint-disable consistent-return */
/* eslint-disable implicit-arrow-linebreak */
const isPropValuesEqual = (subject, target, propNames) =>
  propNames.every((propName) => subject[propName] === target[propName]);

export const getUniqueItemsByProperties = (items, propNames) => {
  const propNamesArray = Array.from(propNames);

  return items.filter(
    (item, index, array) =>
      index === array.findIndex((foundItem) => isPropValuesEqual(foundItem, item, propNamesArray)),
  );
};

export const getLatestItemByProperty = (items, propName) => {
  if (items?.length === 0) return;
  return items?.reduce((recent, item) =>
    (new Date(recent[propName]) > new Date(item[propName]) ? recent : item));
};

export const filterByUniqueObjectsByKey = (array, key) => [
  ...new Map(array.map((action) => [action[key], action])).values(),
];
