export const everyInvoiceSameStatus = (invoices) => (invoices.every((inv) => ['PREOFFER', 'EVALUATING', 'PROCESSING', 'PENDINGDOCUMENTSREVIEW', 'PENDINGRATIFICATION', 'PENDINGTRANSFER'].includes(inv.invoiceStatus))
  || invoices.every((inv) => inv.invoiceStatus === 'OFFER'))
  && invoices.length > 0;

export const everyInvoiceSameCompany = (invoices, companyId) => invoices.every(
  (inv) => inv.company.masterEntity.id === companyId,
);

export const everyInvoiceInProcessing = (invoices) => invoices.every(
  (invoice) => invoice.status === 'PROCESSING',
);

export const xmlMissing = (invoices) => {
  if (!invoices.length) {
    return true;
  }

  return invoices.some(({ documents }) => {
    const xml = documents.find(({ type }) => type.code === 'XML');
    return !xml;
  });
};

export const hasCommercialHeaderButtonDisabled = (invoices) => (
  !everyInvoiceSameStatus(invoices) || !everyInvoiceSameCompany(invoices)
);

export const commercialHeaderActionTooltipTitle = (invoices) => {
  if (invoices.length === 0) {
    return 'Selecciona facturas';
  }
  if (!everyInvoiceSameStatus(invoices)) {
    return 'Las facturas deben tener el mismo estado de operación';
  }
  if (!everyInvoiceSameCompany(invoices)) {
    return 'Las facturas deben tener pertenecer a la misma compañía';
  }
  return '';
};
